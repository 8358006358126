<template>
    <div class="page">
        <LiefengContent :isBack="route.isBack()" @backClick="route.back()">
            <template #title>查阅情况统计</template>
            <template #contentArea>
                <div class="model-base">
                    <div class="model-box">
                        <div class="item-box" v-for="(item, idx) in 5" :key="idx">
                            <p class="title">非示范社区</p>
                            <p class="value">100</p>
                            <p class="subjoin">应读：500</p>
                        </div>
                    </div>
                </div>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3.vue"
import Route from "../communitymobilization/entity/Route"

export default {
    components: { LiefengContent },

    data() {
        return {
            route: Route,
        }
    },
}
</script>

<style lang="less" scoped>
.page {
    .model-base {
        margin: 30px;
        display: flex;
        justify-content: center;

        .model-box {
            display: flex;
            flex-wrap: wrap;

            .item-box {
                float: left;
                width: 150px;
                padding: 20px;
                margin: 0 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 1px solid #e3e3e3;
                border-radius: 10px;

                .value {
                    margin: 30px 0;
                    font-size: 25px;
                    color: #21c065;
                }
            }
        }
    }
}
</style>
